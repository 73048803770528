// Skills icons - https://icon-sets.iconify.design/
import { Icon } from "@iconify/react";

// Navbar Logo image (uncomment below and import your image)
// import newLogo from "./images/yourFileName"

// Projects Images (add your images to the images directory and import below)
import Logo from "./images/logo.svg";

// Hero Images (add your images to the /images directory with the same names)
import HeroLight from "./images/socta.jpeg";
import HeroDark from "./images/socta.jpeg";
// If you change the import names above then you need to change the export names below
export { HeroLight as Light };
export { HeroDark as Dark };

/* START HERE
 **************************************************************
  Add your GitHub username (string - "YourUsername") below.
*/
export const githubUsername = "socta-non-profit";

// Navbar Logo image
export const navLogo = undefined;

// Blog link icon - https://icon-sets.iconify.design/
export const Blog = <Icon icon="ph:link-bold" />;

/* About Me
 **************************************************************
  Add a second paragraph for the about me section.
*/
export const moreInfo =
  "Welcome to the Southern Ontario Cultural Telugu Association (SOCTA)";
export const moreInfo_Line2 = "At SOCTA, we are dedicated to celebrating and promoting the rich cultural heritage of Telugu-speaking communities in Southern Ontario. As a non-profit organization, we strive to create vibrant and inclusive spaces where members of the Telugu diaspora can come together to share their traditions, language, and values."
export const moreInfo_Line3 = "Our Mission: Our mission at SOCTA is to foster a sense of belonging and connection among Telugu individuals and families living in Southern Ontario. Through a variety of cultural events, activities, and initiatives, we aim to preserve and promote the diverse heritage of Telugu-speaking people while also bridging generational and geographical gaps.";
export const moreInfo_Line4 = "What We Do: From traditional festivals like Sankranthi and Ugadi to contemporary celebrations, we organize a diverse range of cultural events throughout the year. We offer Telugu language classes, music and dance workshops, and other artistic programs to nurture and showcase the talents of our community members."
export const moreInfo_Line5 = "Community Outreach: SOCTA actively engages with the broader community through outreach programs, collaborations with other cultural organizations, and participation in local events.\n\nSocial and Networking Opportunities: We provide platforms for networking, socializing, and building connections within the Telugu community through gatherings, picnics, and special events. Get Involved, Whether you're a long-time resident or new to the region, we welcome you to join us in celebrating the vibrant culture and traditions of Telugu-speaking communities. Explore our upcoming events, become a member, or volunteer with us to make a difference and connect with fellow Telugu enthusiasts."
/* Skills
 ************************************************************** 
  Add or remove skills in the SAME format below, choose icons here - https://icon-sets.iconify.design/
*/
export const skillData = [
  {
    id: 1,
    skill: <Icon icon="mdi:language-html5" className="display-4" />,
    name: "HTML5",
  },
  {
    id: 2,
    skill: <Icon icon="ion:logo-css3" className="display-4" />,
    name: "CSS3",
  },
  {
    id: 3,
    skill: <Icon icon="fa6-brands:js" className="display-4" />,
    name: "JavaScript",
  },
  {
    id: 4,
    skill: <Icon icon="ri:bootstrap-fill" className="display-4" />,
    name: "BootStrap",
  },
  {
    id: 5,
    skill: <Icon icon="mdi:react" className="display-4" />,
    name: "React",
  },
  {
    id: 6,
    skill: <Icon icon="file-icons:styledcomponents" className="display-4" />,
    name: "Styled Components",
  },
  {
    id: 7,
    skill: <Icon icon="akar-icons:redux-fill" className="display-4" />,
    name: "Redux",
  },
  {
    id: 8,
    skill: <Icon icon="bi:git" className="display-4" />,
    name: "Git",
  },
  {
    id: 9,
    skill: <Icon icon="fa6-brands:square-github" className="display-4" />,
    name: "GitHub",
  },
];

// Resume link (string - "https://YourResumeUrl") - I am using CloudFront to share my resume (https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html)
export const resume = null;

/* Projects
 ************************************************************** 
  List the repo names (string - "your-repo-name") you want to include (they will be sorted alphabetically). If empty, only the first 3 will be included.
*/
export const filteredProjects = ["example-1", "example-2", "example-3"];

// Replace the defualt GitHub image for matching repos below (images imported above - lines 7-8)
export const projectCardImages = [
  {
    name: "example-1",
    image: Logo,
  },
];

/* Contact Info
 ************************************************************** 
  Add your formspree endpoint below.
  https://formspree.io/
*/
export const formspreeUrl = "https://formspree.io/f/YourEndpoint";
